/*==========================================================
SOCIAL MEDIA TAB SCRIPT
==========================================================*/
// Social Media Tab
$(function() {
    $(".SMLink").click(function(event) {
        event.preventDefault();
        $(".SMItem ").removeClass('current');
        $(this).closest(".SMItem").addClass("current");
    });
});