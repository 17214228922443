/*==========================================================
LAYOUT BUILDER SCRIPTS
==========================================================*/
$(function() {

    // lb-fullMenuBanner
    $('.lb-fullMenuBanner').on('click', '.menu-wrapper', function() {
        $(this).toggleClass('animate');
        $(this).parent().toggleClass('open');
    });

    // scroll past lb-fullMenuBanner when arrow clicked
    $('.lb-fullMenuBanner .arrow').on('click', 'a', function(event) {
        var headerHeight = $('header').outerHeight() - 30;

        var bottom = $('.lb-fullMenuBanner').position().top + $('.lb-fullMenuBanner').outerHeight(true);
        event.preventDefault(); 
        $("html, body").animate({ 
            scrollTop: bottom + headerHeight
        }, 1000);
    });

    //debounce resize instead of standard resize
    $(window).debounceResize(function() {

    });
});

$(window).load(function() {

});