/*==========================================================
LAYOUT BUILDER SCRIPTS
==========================================================*/
$(function() {

    // lb-carousel
    if ($('.lb-carousel').length) {
        $('.lb-carousel').each(function(i, obj) {
            var flickityData = JSON.stringify($('.lb-carouselContainer', this).data('flickity'));
            var objflickityData = $.parseJSON(flickityData);
            if (!objflickityData['contain']) {
                $(this).addClass('containFalse');
            }
        });
    }

    //debounce resize instead of standard resize
    $(window).debounceResize(function() {

    });
});

$(window).load(function() {

    // lb-carousel
    if ($('.lb-carousel.containFalse').length) {
        triggerResizeEvent();
    }
});