/*==========================================================
RESPONSIVE SCRIPTS
==========================================================*/

$(function() {
    //debounce resize instead of standard resize
    $(window).debounceResize(function() {


    });

    $(window).load(function() {

    });
});