/*==========================================================
FULL ROW FEATURE SCRIPTS
==========================================================*/

function fullRowFeatureImageHeight() { 
    if ($('#uber').hasClass('layoutBuilder') && !$('#uber').hasClass('interior')) {

        if (window.matchMedia("(min-width: 768px)").matches) {
            $('.Full-Row-Feature .FullImage .fbg-col-xs-12').css('min-height', '0px');
            $('.Full-Row-Feature .FullImage .fbg-col-xs-12').addClass('defaultFit');
    
            var contentHeights = $('.Full-Row-Feature .column:not(FullImage)').map(function() {
                return $(this).height();
            }).get();
    
            var maxContentHeight = Math.max.apply(null, contentHeights);
    
            var imageHeights = $('.Full-Row-Feature .FullImage img').map(function() {
                return $(this).height();
            }).get();
    
            var maxImagetHeight = Math.max.apply(null, imageHeights);
    
            if (maxContentHeight > maxImagetHeight) {
                $('.Full-Row-Feature .FullImage .fbg-col-xs-12').css('min-height', maxContentHeight + 'px');
                $('.Full-Row-Feature .FullImage .fbg-col-xs-12').removeClass('defaultFit');
                $('.Full-Row-Feature .FullImage .fbg-col-xs-12').addClass('objectFit');
                $('.Full-Row-Feature .FullImage .fbg-col-xs-12 img').attr('data-object-fit', 'cover'); // for IE
                objectFitPolyfill(); // for IE
            } else {
                $('.Full-Row-Feature .FullImage .fbg-col-xs-12').css('min-height', '0px');
                $('.Full-Row-Feature .FullImage .fbg-col-xs-12').addClass('defaultFit');
                $('.Full-Row-Feature .FullImage .fbg-col-xs-12').removeClass('objectFit');
            }
        } else {
            $('.Full-Row-Feature .FullImage .fbg-col-xs-12').css('min-height', '0px');
            $('.Full-Row-Feature .FullImage .fbg-col-xs-12').removeClass('objectFit');
            $('.Full-Row-Feature .FullImage .fbg-col-xs-12').addClass('defaultFit');
        }
    }
}

$(function() {
    fullRowFeatureImageHeight();

    //debounce resize instead of standard resize
    $(window).debounceResize(function() {
        fullRowFeatureImageHeight();
    });

    $(window).load(function() {
        fullRowFeatureImageHeight();
    });
});