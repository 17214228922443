/*==========================================================
LAYOUT BUILDER SCRIPTS
==========================================================*/
$(function() {
    // lb-imageBox
    if ($('#uber').hasClass('layoutBuilder')) {
        $('.lb-imageBox_header').matchHeight();
    }

    //debounce resize instead of standard resize
    $(window).debounceResize(function() {

    });
});

$(window).load(function() {

});