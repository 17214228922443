/*==========================================================
NUMBER COUNTER SCRIPTS
==========================================================*/
$(function() {
    var numberCounterWrapper = $(".lb-numberCounter");
    var numberCounter = $(".lb-numberCount");

    if (numberCounterWrapper.length) {
        numberCounterWrapper.each(function(i) {
            $(numberCounter, this).each(function(j) {
                var numberCounterData = JSON.stringify($(this).data('numbercount'));
                var objnumberCounterData = $.parseJSON(numberCounterData);
                var startVal = objnumberCounterData['startVal'];
                var endVal = objnumberCounterData['endVal'];
                var decimalPlaces = objnumberCounterData['decimalPlaces'];
                var duration = objnumberCounterData['duration'];
                var decimalChar = objnumberCounterData['decimalChar'];
                var separatorChar = objnumberCounterData['separatorChar'];
                var options = {
                    useEasing: true,
                    useGrouping: false,
                    decimal: decimalChar,
                    separator: separatorChar
                };
                var numberCounterAnimation = new CountUp($(numberCounter)[j], startVal, endVal, decimalPlaces, duration, options);
                numberCounterAnimation.start();
            });
        });
    }

    //debounce resize instead of standard resize
    $(window).debounceResize(function() {

    });

    $(window).load(function() {

    });
});