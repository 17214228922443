/*==========================================================
EVENTS CAROUSEL SCRIPTS
==========================================================*/

$(function() {

    //debounce resize instead of standard resize
    $(window).debounceResize(function() {
    });

    $(window).load(function() {
        $('.lb-fullRowEventsCarousel-container').flickity({
            autoPlay: 6000,
            pauseAutoPlayOnHover: true,
            freeScroll: true,
            contain: true, 
            wrapAround: true, 
            cellAlign: "left",
            pageDots: false
        });

        setTimeout(
            function() {
                $('.lb-fullRowEventsCarousel-container').flickity('resize');
            }, 300);
    });
});