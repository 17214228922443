/*==========================================================
LAYOUT BUILDER SCRIPTS
==========================================================*/
function fullMenuBannerHeight() { 
    if ($('#uber').hasClass('layoutBuilder')) {
        var carouselContainer = $('.lb-fullMenuBannerCarousel .lb-fullMenuBannerCarousel-carouselContainer');
        var carouselFlickity = $('.lb-fullMenuBannerCarousel .lb-fullMenuBannerCarousel-carouselContainer .flickity-viewport');
        var carouselImg = $('.lb-fullMenuBannerCarousel .lb-fullMenuBannerCarousel-carouselContainer .lb-fullMenuBannerCarousel-carouselCell img');
        var carouselWrapper = $('.lb-fullMenuBannerCarousel .lb-fullMenuBannerCarousel-carouselContainer .lb-fullMenuBannerCarousel-carouselCell .lb-fullMenuBannerCarousel-carouselWrapper');

        if (window.matchMedia("(min-width: 992px)").matches) {
            carouselContainer.css('height', '680px');
            carouselFlickity.css('min-height', '680px');
            carouselImg.css('min-height', '680px');
            var carouselHeight = carouselContainer.outerHeight();
            var carouselImageHeight = carouselImg.outerHeight();
    
            if (carouselImageHeight > carouselHeight) {
                offset = (carouselImageHeight - carouselHeight) / 2;
                carouselWrapper.css('bottom', offset + 'px');
            } else {
                carouselWrapper.css('bottom', '0');
            }
        }
        if (window.matchMedia("(max-width: 991px)").matches) {
            carouselContainer.css('height', '520px');
            carouselFlickity.css('min-height', '520px');
            carouselImg.css('min-height', '520px');
            carouselWrapper.css('bottom', '0');
            var carouselHeight = carouselContainer.outerHeight();

            var contentHeights = carouselWrapper.map(function() {
                return $(this).height();
            }).get();
            var maxContentHeight = (Math.max.apply(null, contentHeights)) + 120; //120px buffer

            if (maxContentHeight > carouselHeight) {
                carouselContainer.css('height', maxContentHeight + 'px');
                carouselFlickity.css('min-height', maxContentHeight + 'px');
                carouselImg.css('min-height', maxContentHeight + 'px');
            } 
        }
    }
}

$(function() {
    // lb-fullMenuBannerCarousel
    $('.lb-fullMenuBannerCarousel').on('click', '.menu-wrapper', function() {
        $(this).toggleClass('animate');
        $(this).parent().toggleClass('open');
    });

    // scroll past lb-fullMenuBannerCarousel when arrow clicked
    $('.lb-fullMenuBannerCarousel .arrow').on('click', 'a', function(event) {
        var headerHeight = $('header').outerHeight() - 30;

        var bottom = $('.lb-fullMenuBannerCarousel').position().top + $('.lb-fullMenuBannerCarousel').outerHeight(true);
        event.preventDefault(); 
        $("html, body").animate({ 
            scrollTop: bottom + headerHeight
        }, 1000);
    });

    fullMenuBannerHeight();

    //debounce resize instead of standard resize
    $(window).debounceResize(function() {
        fullMenuBannerHeight();
    });
});

$(window).load(function() {
    fullMenuBannerHeight();
    triggerResizeEvent();
});