/*==========================================================
NEWS CAROUSEL SCRIPTS
==========================================================*/

function newsContentSize() {
    $('.lb-fullRowNewsCarousel .lb-fullRowNewsCarousel-cell .lb-fullRowNewsCarousel-text').css('min-height', 0);
    var elementHeights = $('.lb-fullRowNewsCarousel .lb-fullRowNewsCarousel-cell .lb-fullRowNewsCarousel-text').map(function() {
        return $(this).outerHeight();
    }).get();

    var maxContentHeight = Math.max.apply(null, elementHeights);
    $('.lb-fullRowNewsCarousel .lb-fullRowNewsCarousel-cell .lb-fullRowNewsCarousel-text').css('min-height', maxContentHeight + 'px');
    $('.lb-fullRowNewsCarousel-container').flickity('resize');
}

$(function() {
    
    //debounce resize instead of standard resize
    $(window).debounceResize(function() {
        newsContentSize();
    });

    $(window).load(function() {
        $('.lb-fullRowNewsCarousel-container').flickity({
            autoPlay: 6000,
            pauseAutoPlayOnHover: true,
            freeScroll: true,
            contain: true, 
            wrapAround: true, 
            cellAlign: "left",
            pageDots: false
        });
        newsContentSize();

        setTimeout(
            function() {
                $('.lb-fullRowNewsCarousel-container').flickity('resize');
            }, 300);
    });
});