/*==========================================================
LAYOUT BUILDER SCRIPTS
==========================================================*/
// lb-imageBoxHover
function lbImageBoxHoverWidth() {
    $(".lb-imageBoxHover-caption").each(function(index) {
        if ($(this).outerWidth() <= 330) {
            $(this).parent().addClass("stacked");
        } else {
            $(this).parent().removeClass("stacked");
        }
    });
}

$(function() {
    // lb-imageBoxHover
    if ($('#uber').hasClass('layoutBuilder')) {
        $('.lb-imageBoxHover-caption').matchHeight();
    }
    lbImageBoxHoverWidth();

    $('body').on('touchend', '.lb-imageBoxHover', function(event) {
        event.stopPropagation();
        event.preventDefault();
        if ($(this).outerWidth() <= 330) {
            location.href = $(this).attr('href');
        }

        if ($(this).hasClass('touched')) {
            location.href = $(this).attr('href');
        } else {
            $('.lb-imageBoxHover').removeClass('touched');
            $(this).addClass('touched');
        }
    });

    $('body').on('touchend touchcancel', function(event) {
        $('.lb-imageBoxHover').removeClass('touched');
    });

    //debounce resize instead of standard resize
    $(window).debounceResize(function() {
        lbImageBoxHoverWidth();
    });
});

$(window).load(function() {
    // lb-imageBoxHover
    lbImageBoxHoverWidth();

    if ($('html').hasClass('ie')) {
        // //trigger resize to properly set lb-imageBoxHover-caption matchHeight on IE11 on load
        triggerResizeEvent();
    }
});